import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <HeroSection />
      <FeaturesSection />
      <ServicesSection />
      <TestimonialsSection />
      <CallToActionSection />
      <Footer />
    </div>
  );
}

const Header = () => (
  <header className="App-header">
    <div className="logo">TechExtendio</div>
    <nav>
      <ul>
        <li><a href="#home">Home</a></li>
        <li><a href="#solutions">Solutions</a></li>
        <li><a href="#software">Software</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>
    </nav>
    <button className="cta">Get Started</button>
  </header>
);

const HeroSection = () => (
  <section className="Hero">
    <h1>Empowering Your Business with Cutting-Edge Data Science Solutions</h1>
    <p>Innovative software and tailored solutions to drive your data-driven success.</p>
    <button className="cta">Explore Solutions</button>
  </section>
);

const FeaturesSection = () => (
  <section className="Features">
    <h2>Why Choose TechExtendio?</h2>
    <div className="feature-cards">
      <div className="feature-card">
        <h3>Advanced Analytics</h3>
        <p>Leverage AI to gain actionable insights.</p>
      </div>
      <div className="feature-card">
        <h3>Custom Software</h3>
        <p>Tailored solutions to fit your unique needs.</p>
      </div>
      <div className="feature-card">
        <h3>Data Integration</h3>
        <p>Seamlessly integrate with your existing systems.</p>
      </div>
      <div className="feature-card">
        <h3>Expert Support</h3>
        <p>24/7 support from our data science experts.</p>
      </div>
    </div>
  </section>
);

const ServicesSection = () => (
  <section className="Services">
    <h2>Our Services</h2>
    <p>Data Analysis & Visualization, Machine Learning & AI, Data Engineering, Software Development</p>
    <button className="cta">Learn More</button>
  </section>
);

const TestimonialsSection = () => (
  <section className="Testimonials">
    <h2>What Our Clients Say</h2>
    <div className="testimonial-carousel">
      <div className="testimonial">
        <p>"TechExtendio transformed our data management process."</p>
        <span>– Client Name</span>
      </div>
      {/* Additional testimonials */}
    </div>
  </section>
);

const CallToActionSection = () => (
  <section className="CallToAction">
    <h2>Ready to Transform Your Business?</h2>
    <p>Get in touch with our experts today.</p>
    <button className="cta">Contact Us</button>
  </section>
);

const Footer = () => (
  <footer className="App-footer">
    <div className="footer-content">
      <div className="quick-links">
        <a href="#solutions">Solutions</a>
        <a href="#software">Software</a>
        <a href="#about">About</a>
        <a href="#contact">Contact</a>
      </div>
      <div className="social-media">
        {/* Social Media Icons */}
      </div>
      <div className="contact-info">
        <p>Contact us at info@techextendio.com</p>
      </div>
    </div>
    <p>© 2024 TechExtendio. All rights reserved.</p>
  </footer>
);

export default App;
